
import React, { useState } from "react";
import * as Images from "../../../utilities/images";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { updateProfileAction } from "../../../redux/slices/auth";


const UpdateProfile = () => {

    const dispatch = useDispatch();
    const auth =  useSelector((state)=>state.auth)
    
    const [ updateProfile , setUpdateProfile ] = useState({
        name: auth.userInfo.name,
        email: auth.userInfo.email
    });

    const handleChange = (e) => {
        const { value, name } = e.target;
        setUpdateProfile({ ...updateProfile, [name]: value.trim() });
    }

    const handleSubmit = () => {

        if (!updateProfile.name) {
            toast.error("Please enter name");
            return;
        }

        if (!updateProfile.email) {
            toast.error("Please enter email");
            return;
        }

        if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(updateProfile.email)){
            toast.error("Please add a valid email");
            return
        }
            
        
        let params = {
            name : updateProfile.name,
            email: updateProfile.email
        }
        
        dispatch(updateProfileAction({
            ...params, cb(res) {
                if (res.status == 200) {
                    // toast.success("Password updated successfully!");
                }
                else {
                    toast.error("Something went wrong");
                }
            }
        }))
    }




    return (
        <>
            <h1>Update Profile</h1>
            <div className="boxOuter">
                <form>
                    <div class="form-group mb-4">
                        <label class="input_label" for="uname">Name</label>
                        <input type="text" placeholder="Enter Name" name="name" value={updateProfile.name}  onChange={(e) => handleChange(e)} className="form-control input_box " />
                        <img src={Images.Profile} alt='LeftIcon' className="inputIcon" />
                    </div>
                    <div class="form-group mb-4">
                        <label class="input_label" for="uname">Email</label>
                        <input type="email" placeholder="Enter email address" name="email" value={updateProfile.email} onChange={(e) => handleChange(e)} className="form-control input_box " />
                        <img src={Images.Message} alt='LeftIcon' className="inputIcon" />
                    </div>
                    <div className='modal_btn w-50'>
                        <button class="submit_btn w-100" type="button" onClick={()=>handleSubmit()} ><span className="submit_text">Update</span></button>
                    </div>
                </form>
            </div>
        </>
    )

}

export default UpdateProfile;